import React, { useEffect, useState } from "react";
import GameSlideFull from "../../../components/game/gamse-slide-full";
import { getGameElement } from "../../../api/base-api";
import { getWebPathParam } from "../../../utils/converter";
import { INVITED_USERS, USER } from "../../endpoints";
import coin from "../../../resources/game/coin.svg";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import LoaderWrapper from "../../../components/loader/loader-wrapper";
import { PlayerCard } from "./game-levels";
import { Adsense } from "@ctrl/react-adsense";

const GameInvite = ({ isOpen, setIsOpen }) => {


    const { userGameDetails } = useSelector((state) => state.game);

   
    // eslint-disable-next-line
    let [isLoading, setLoading] = useState(true);

    // eslint-disable-next-line
    let [oneTimeTasks, setOneTimeTasks] = useState([]);
    // eslint-disable-next-line
    let [users, setUsers] = useState([]);


    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;


        async function fetchTasks() {
            if (isOpen) {
                setLoading(true);
                try {
                    const r = await getGameElement(getWebPathParam([USER, INVITED_USERS]), signal);
                    setUsers(r);
                } catch (e) {
                    if (signal.aborted) {
                        setLoading(true);
                    }
                } finally {
                    if (signal.aborted) {
                        setLoading(true);
                    } else {
                        setLoading(false);
                    }
                }
            }
        }

        fetchTasks();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen]);


    return (
        <GameSlideFull
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            overFlow={'overflow-y-scroll'}
        >

            <div className="overflow-y-scroll">
                <div className="mt-16 text-center w-full">
                    <div className="text-3xl font-bold tracking-tight text-white">
                        Invita i tuoi amici!
                    </div>
                    <p className="text-md mt-1 font-normal tracking-tight text-white flex items-center justify-center">
                        Tu e i tuoi amici riceverete 
                        <span className="flex items-center ml-1 font-bold">
                            
                            <NumberFormat
                                value={10000}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"+"}
                            />
                            <img src={coin} className="w-6 h-6 ml-1" alt="" />
                        </span>
                    </p>
                </div>

                <div className="p-4 mt-4">
                    <p className="py-6 mb-3  bg-gray-800 border-2 border-dashed text-white text-center text-4xl rounded-2xl">
                        {userGameDetails.invitation_code}
                    </p>

                    <GameBtn 
                        isFullWith={true}
                        label={'Condividi codice invito'}
                        onClick={async () => {
                            const textToShare = "⚽ Vieni a giocare su Minigoal! Diventa una leggenda, sblocca premi incredibili e regali esclusivi!\n🔥 Iscriviti ora e ricevi subito +10k coin come bonus di benvenuto!\nUsa il mio codice invito: " + userGameDetails.invitation_code + "\nNon perdere questa occasione!\nScarica subito l'app\nhttps://bit.ly/m/minigoal";

                            if (navigator.share) {
                                try {
                                    await navigator.share({
                                        title: '',
                                        text: textToShare,
                                    });
                                } catch (error) {
                                   
                                }
                            } else {
                                try {
                                    await navigator.clipboard.writeText(userGameDetails.invitation_code);
                                    alert("Il codice invito è stato copiato negli appunti. Puoi incollarlo manualmente per condividerlo.");
                                } catch (error) {
                                    alert("Non è possibile condividere il codice invito né copiarlo negli appunti.");
                                }
                            }
                        }}
                    />
                  
                </div>


                <div className="my-2">
                    <Adsense
                        client="ca-pub-7934619462197808"
                        slot="6849545691"
                        style={{ display: 'block' }}
                        layoutKey="-fb+5w+4e-db+86"
                        adTest="true"
                        format="fluid"
                    />
                </div>


                {
                    users.length > 0 && (
                        <div className="p-4 mt-4">
                            <div className="text-md font-bold tracking-tight text-white">
                                Lista dei tuoi amici
                            </div>


                            <ul className="overflow-y-scroll p-2">
                                <LoaderWrapper isLoading={isLoading}>
                                    {users.map((userLevel, index) => (
                                        <PlayerCard index={index} userLevel={userLevel.invited_user} isActivePosition={false} isActiveErned={true} earnedPoint={userLevel.earned_point} />
                                    ))}
                                </LoaderWrapper>
                            </ul>


                        </div>
                    )
                }
                
           </div>
           

        </GameSlideFull>
    );
};

const GameBtn = ({ label, onClick, isFullWith = false, disabled = false }) => {

    return (
        <button
            type="submit"
            onClick={onClick}
            className={`${isFullWith ? "w-full" : ""}  cursor-pointer disabled:opacity-70 disabled:cursor-not-allowed flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold  text-white bg-red-700`}
            disabled={disabled}
        >{label}</button>
    )
}



export default GameInvite;
