import React, {useEffect} from "react";
import Loading from "../../components/layout/loading";

const Privacy = () => {

    useEffect(() => {
        window.location.href = "https://www.iubenda.com/privacy-policy/32702144/legal";
    }, []);

    return (
        <Loading />
    )


};

export default Privacy;
