import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import noImage from "../../../resources/images/user-placeholder.jpg";
import coin from "../../../resources/game/coin.svg";
import goalImage from "../../../resources/game/goal.svg";
import { getGameElement, postElement, postGameElement } from "../../../api/base-api";
import { getWebPathParam } from "../../../utils/converter";
import { ChevronRightIcon, DotsHorizontalIcon, ExclamationIcon, UserIcon, UsersIcon } from "@heroicons/react/outline";
import GameLevels from "./game-levels";
import { LEVEL, ME, USER } from "../../../api/endpoints";
import LoaderWrapper from "../../../components/loader/loader-wrapper";
import { formatNumber, GAME_LEVELS } from "../../../constants/config";

import { Menu, Transition } from '@headlessui/react'
import TrophyIcon from "../../../resources/icons/TrophyIcon";
import NumberFormat from "react-number-format";
import GameEarning, { GameEarningAlert } from "./game-earning";
import { FOOTBALL_POSITIONS, UNDER_EDGES } from "../../../constants/config";
import GameInvite from "./game-invite";
// eslint-disable-next-line
import GameTap from "./game-tap";
import { useDispatch } from "react-redux";
import { setGasCounter, updateGameData, setCurrentPoint, updatePreviousLevel, onSetUserGameDetails } from '../../../reducers/gameReducer';
import { clearGameToken } from "../../../utils/tokenUtil";
import { useNavigate } from "react-router-dom";
import { GAME, GAME_API, PLAY, POINT, SEND, START } from "../../endpoints";
import { Controller, useForm } from "react-hook-form";
import GameSlideAlert from "../../../components/game/gamse-slide-alert";
import InputSelect from "../../../components/form/input-select";
import InputTextBlack from "../../../components/form/input-text-black";
import { InputPrimarySubmit } from "./game-onboarding";
import { emailRegex } from "../../../constants/regex";
import InputFormattedNumber from "../../../components/common/form/input-formatted-number";
import GameWhackBall from "./game-whack-ball";
import { Adsense } from "@ctrl/react-adsense";


const Game = () => {
    const dispatch = useDispatch();

    const { detail } = useSelector((state) => state.user);
    const { userGameDetails, currentPoint, pointPerTap, nextLevelPoint, curentLevel, previousLevel } = useSelector((state) => state.game);

    //API
    const [refreshData, setRefreshData] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const [isOpenForm, setOpenForm] = useState(false);

    //Levels Overview
    const [isOpenLevelOverview, setOpenLevelOverview] = useState(false);
    const [levels, setLevels] = useState([]);
    const [isLoadingLevels, setLoadingLevels] = useState(true);


    //Levels Overview
    const [isOpenGameEarning, setOpenGameEarning] = useState(false);
    const [isOpenGiftCoin, setOpenGiftCoin] = useState(false);

    //Invite Overview
    const [isOpenInvite, setOpenInvite] = useState(false);
    let navigate = useNavigate();


    let [isOpenErningCofirm, setOpenErningCofirm] = useState(false);
    let [erningPoint, setErningPoint] = useState(0);



    // Function to calculate percentage
    const calculatePercentage = () => {
        
        let levelPoint = nextLevelPoint;
        let levelCurrentPoint = currentPoint;

        if (previousLevel){
            //20K
            levelPoint = levelPoint - previousLevel.next_level;
            levelCurrentPoint = levelCurrentPoint - previousLevel.next_level;
        }

        if (curentLevel && curentLevel.level_id === 10){
            return 100;
        }

        return (levelCurrentPoint / levelPoint) * 100;
    }; 

    function featchGameDetails() {
        if (refreshData === null) {
            setLoading(true);
        }
        getGameElement(getWebPathParam([USER, ME]))
        .then(async (response) => {
            dispatch(onSetUserGameDetails(response));
            dispatch(updateGameData(response.level));
            dispatch(setGasCounter(response.user_point.reset_point));
            dispatch(setCurrentPoint(response.user_point.points));


            if (response.notify_new_earned){
                setErningPoint(response.new_earned);
                setOpenErningCofirm(true);
            }


            setLoading(false);
            setRefreshData(false);
        })
        .catch((e) => {
            clearGameToken();
            navigate(getWebPathParam([GAME, PLAY]));
            setRefreshData(false);
            setLoading(false);
        });
     
    }

    useEffect(() => {
        // Set a timeout to change the message after 1 second
        function featchGameLevels() {
            setLoadingLevels(true);
            getGameElement(getWebPathParam([LEVEL]))
                .then(async (response) => {
                    setLevels(response);
                    setLoadingLevels(false);
                })
                .catch((e) => {
                    setLoadingLevels(false);
                });
        }

        featchGameLevels();
        featchGameDetails();
        // eslint-disable-next-line
    }, []); 


    useEffect(() => {
        if (levels.length > 0 && curentLevel !== false && curentLevel.level_id > 1){
            let pLevel = levels.find(r => r.levid === (curentLevel.level_id - 1));
            dispatch(updatePreviousLevel(pLevel));
        }
    }, [curentLevel, levels, dispatch]);



    return (
        <LoaderWrapper isLoading={isLoading || isLoadingLevels}>
            <div className="px-6 pt-6 pb-16 bg-black mb-2">
                <div className="flex items-center justify-between space-x-5">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                <img
                                    alt=""
                                    src={detail?.picture?.reference ?? noImage}
                                    className="h-8 w-8 rounded-full object-cover"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <h1 className="text-sm font-bold text-white ">
                                {detail?.firstname ?? ""} {detail?.lastname ?? ""}
                            </h1>
                        </div>
                    </div>

                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                <DotsHorizontalIcon
                                    className="-mr-1 ml-2 h-5 w-5 text-white"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56  z-50 origin-top-right rounded-md backdrop-blur-sm bg-gray-800/80 focus:outline-none">
                                <div className="px-1 py-1 ">


                                   
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    setOpenForm(true); 
                                                }}
                                                className={`text-white  group flex w-full items-center rounded-md px-2 py-3 text-md`}
                                            >
                                                <UserIcon
                                                    className="mr-2 h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                                Impostazioni
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    setOpenLevelOverview(true);
                                                }}
                                                className={`text-white  group flex w-full items-center rounded-md px-2 py-3 text-md`}
                                            >
                                                <TrophyIcon
                                                    className="mr-2 h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                                Classifica
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button onClick={() => {
                                                setOpenInvite(true);                                                
                                            }} className={`text-white  group flex w-full items-center rounded-md px-2 py-3 text-md`}>
                                                <UsersIcon
                                                    className="mr-2 h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                                Invita un amico
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    setOpenGameEarning(true);
                                                }}
                                                className={`text-white  group flex w-full items-center rounded-md px-2 py-3 text-md`}
                                            >
                                                <img src={coin} className="mr-2 h-5 w-5" alt="" />
                                                Guadagna coin
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    setOpenGiftCoin(true);
                                                }}
                                                className={`text-white  group flex w-full items-center rounded-md px-2 py-3 text-md`}
                                            >
                                                <img src={coin} className="mr-2 h-5 w-5" alt="" />
                                                <img src={coin} className="-ml-4 mr-2 h-5 w-5" alt="" />

                                                Regala coin
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>



                </div>


               
            </div>

            <div className="col-span-1 -mt-14 flex-grow flex-col divide-y border-t-2 border-red-600 bg-gray-900 divide-gray-200 rounded-t-[50px] text-center ">
                <div className="flex flex-1 flex-col p-6">



                    <dl className="grid gap-x-8 grid-cols-3 text-center" onClick={() => {
                        setOpenForm(true);
                    }}>
                        <div className="overflow-hidden bg-gray-800 p-2 rounded-lg" >
                            <dt className="truncate  font-medium text-white uppercase" style={{ fontSize: "0.7rem" }}>
                                Ruolo
                            </dt>
                            <dd className="text-xs font-semibold tracking-tight text-white">
                                <div className="flex items-center justify-center">
                                    {FOOTBALL_POSITIONS.find(r => r.value === userGameDetails.player_role)?.label ?? '-'}
                                </div>
                            </dd>
                        </div>

                        <div className="overflow-hidden bg-gray-800 p-2 rounded-lg" >
                            <dt className="truncate font-medium text-white uppercase" style={{ fontSize: "0.7rem" }}>
                                Leva
                            </dt>
                            <dd className="text-xs font-semibold tracking-tight text-white">
                                <div className="flex items-center justify-center">
                                    {UNDER_EDGES.find(r => r.value === userGameDetails.underage)?.label ?? '-'}
                                </div>
                            </dd>
                        </div>

                        <div className="overflow-hidden bg-gray-800 p-2 rounded-lg" >
                            <dt className="truncate font-medium text-white uppercase" style={{ fontSize: "0.7rem" }}>
                                Squadra
                            </dt>
                            <dd className="text-xs font-semibold tracking-tight text-white">
                                <div className="flex items-center justify-center">
                                    {userGameDetails.team !== '' ? userGameDetails.team : '-'}
                                </div>
                            </dd>
                        </div>
                    </dl>




                    <div className="flex items-center justify-center mt-3">
                        <img src={coin} className="w-14 h-14 mr-3" alt="" />
                        <div className="my-3 text-4xl font-bold tracking-tight text-white">
                            <NumberFormat
                                value={currentPoint}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={""}
                            />
                        </div>
                    </div>
                   
                  
                    

                    <GameWhackBall
                        image={GAME_LEVELS?.find(r => r.level === curentLevel.level_id)?.image ?? ''}
                    />

                    {/**
                    
                      <GameTap 
                        image={GAME_LEVELS?.find(r => r.level === curentLevel.level_id)?.image ?? ''}
                    />
                     * 
                     */}
                  
                    <div className="mt-4 mb-2 w-full" onClick={() => {
                        setOpenLevelOverview(true);
                    }}>
                        <div className="flex justify-between w-full px-1">
                            <span className="text-sm text-white font-semibold flex items-center">{curentLevel.level_name} <ChevronRightIcon className="h-4 w-4" /> </span>
                            <span className="text-sm text-white font-semibold">Livello {curentLevel.level_id}/{levels.length}</span>
                        </div>
                        <div className="bg-gray-800 w-full h-4 mt-1 rounded-full">
                            <div className="bg-gradient-to-r from-yellow-300 to-red-500 h-4 rounded-full" style={{ width: (calculatePercentage()) + "%" }}></div>
                        </div>
                    </div>


                    <dl className="mt-2 mb-6 grid gap-5 grid-cols-2">
                        <div className="overflow-hidden rounded-lg bg-gray-800 rounded-tl-lg border-red-600 py-2 shadow">
                            <dt className="truncate text-sm font-medium text-white" style={{ fontSize: "0.775rem" }}>
                                Goal per tap
                            </dt>
                            <dd className="text-md font-bold tracking-tight text-white">

                                <div className="flex items-center justify-center">
                                    <img src={goalImage} className="w-6 h-6 mr-1" alt="" />
                                    +{formatNumber(pointPerTap)}
                                </div>


                            </dd>
                        </div>

                        <div className="overflow-hidden rounded-lg bg-gray-800 rounded-tl-lg border-red-600 py-2 shadow">
                            <dt className="truncate text-sm font-medium text-white" style={{ fontSize: "0.775rem" }}>
                                Prossimo Livello
                            </dt>
                            <dd className="text-md font-bold tracking-tight text-white">
                                <div className="flex items-center justify-center">
                                    {
                                        (curentLevel && curentLevel.level_id === 10) ? (
                                            <>-</>
                                        ) : (
                                            <>
                                                <img src={coin} className="w-6 h-6 mr-1" alt="" />
                                                {formatNumber(nextLevelPoint)}

                                            </>
                                        )
                                    }
                                </div>
                            </dd>
                        </div>
                    </dl>

                    <div className=" mt-3">
                        <Adsense
                            client="ca-pub-7934619462197808"
                            slot="4335402306"
                            style={{ display: 'block' }}
                            layoutKey="-fb+5w+4e-db+86"
                            adTest="true"
                            format="fluid"
                        />
                    </div>
                    
                </div>
            </div>


            <GameLevels
                isOpen={isOpenLevelOverview}
                curentLevel={curentLevel}
                levels={levels}
                setIsOpen={(r) => {
                    setOpenLevelOverview(r)
                }}
            />

            <GameEarning
                isOpen={isOpenGameEarning}
                setIsOpen={(r) => {
                    setOpenGameEarning(r)
                }}
                onNeedRefresh={() => {
                    featchGameDetails();
                }}
            />

            <GameInvite
                isOpen={isOpenInvite}
                setIsOpen={(r) => {
                    setOpenInvite(r)
                }}
            />


            <GameEarningAlert isOpenAlert={isOpenErningCofirm} setOpenAlert={setOpenErningCofirm} points={erningPoint} />

            <UpdateUserData isOpenPanel={isOpenForm} setOpenPanel={setOpenForm} userDetails={userGameDetails} onUpdateData={() => {
                featchGameDetails()
            }} />



            <GitfCoin isOpenAlert={isOpenGiftCoin} setOpenAlert={setOpenGiftCoin} currentPoint={currentPoint} onUpdateData={() => {
                featchGameDetails()
            }} />

        </LoaderWrapper>
    );
};

const UpdateUserData = ({ userDetails, isOpenPanel, setOpenPanel, onUpdateData }) => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({ mode: 'onBlur' });
    const [isLoading, setLoading] = useState(false);

    const onSubmit = async (data) => {


        if (data) {
            if (data['under_edges']) {
                data['underage'] = data['under_edges'].value;
                delete data['under_edges'];
            } else {
                delete data['under_edges'];
                data['underage'] = ' ';
            }

            if (data['position']) {
                data['player_role'] = data['position'].value;
                delete data['position'];
            } else {
                data['player_role'] = ' ';

            }

            if (data['team'] === '') {
                delete data['team'];
            }
        }


        setLoading(true);
        postElement(getWebPathParam([GAME_API, START]), data)
            .then(async (response) => {
                onUpdateData();
                setOpenPanel(false);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
       
    };

    useEffect(() => {
        if (userDetails) {
            if (userDetails.team !== ''){
                setValue('team', userDetails.team);
            }

            if (UNDER_EDGES.find(r => r.value === userDetails.underage)){
                setValue('under_edges', UNDER_EDGES.find(r => r.value === userDetails.underage));
            }


            if (FOOTBALL_POSITIONS.find(r => r.value === userDetails.player_role)) {
                setValue('position', FOOTBALL_POSITIONS.find(r => r.value === userDetails.player_role));
            }
           
        }
    }, [userDetails, setValue]);


    return(
        <GameSlideAlert
            isOpen={isOpenPanel}
            setIsOpen={(r) => {
                setOpenPanel(r);
            }}
        >
            <div className="py-4">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <form className="md:col-span-2  py-6 px-4  rounded-md shadow-md" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-5">
                            <InputTextBlack
                                id={'team'}
                                type={'text'}
                                errors={errors.team}
                                input={{ ...register("team", { required: false }) }}
                                placeholder={'Inserisci squadra di appartenenza'}
                                label={'Squadra di appartenenza'}
                            />
                        </div>

                        <div className="mb-5">
                            <Controller
                                name="under_edges"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputSelect
                                        label={'Categoria/Leva'}
                                        placeholder={'Seleziona categoria/leva'}
                                        name={name}
                                        options={UNDER_EDGES}
                                        value={value}
                                        isInsideTarget={true}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        isLoading={false}
                                        isDisabled={false}
                                        isClearable={true}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>

                        <div className="mb-5">
                            <Controller
                                name="position"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputSelect
                                        label={'Ruolo'}
                                        placeholder={'Seleziona ruolo'}
                                        name={name}
                                        options={FOOTBALL_POSITIONS}
                                        value={value}
                                        isInsideTarget={true}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        isLoading={false}
                                        isDisabled={false}
                                        isClearable={true}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>



                        <InputPrimarySubmit
                            isFullWith={true}
                            isLoading={isLoading}
                            label={'Aggiorna Dati!'}
                        />


                    </form>
                </div>
            </div>

        </GameSlideAlert>
    )
}


const GitfCoin = ({ isOpenAlert, setOpenAlert, onUpdateData, currentPoint }) => {


    const { register, handleSubmit, watch, control, formState: { errors }, setValue } = useForm({ mode: 'onBlur' });
    const [isLoading, setLoading] = useState(false);
    const [excep, setExcep] = useState(false);


    const onSubmit = async (data) => {
        setLoading(true);
        setExcep(false);
        postGameElement(getWebPathParam([POINT, SEND]), data)
            .then(async (response) => {
                onUpdateData();
                setOpenAlert(false);
                setLoading(false);
                setValue('email', null);
                setValue('points', null)
            })
            .catch((e) => {
                setLoading(false);
                setExcep(true);
            });

    };
    

    useEffect(() => {
        if (watch('points')) {
            if (watch('points') > currentPoint) {
                setValue('points', currentPoint);
            }

        }
        // eslint-disable-next-line
    }, [watch('points'), setValue]);


    return (
        <GameSlideAlert
            isOpen={isOpenAlert}
            setIsOpen={setOpenAlert}
        >
            
            <div className="max-w-[18rem] mx-auto">
                <div className="flex flex-col    ">

                    <div className="flex items-center justify-center mt-3">
                        <img src={coin} className="w-14 h-14 mr-3" alt="" />
                        <div className="my-3 text-4xl font-bold tracking-tight text-white">
                            <NumberFormat
                                value={currentPoint - (watch('points') ? watch('points') : 0)}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={""}
                            />

                           
                        </div>
                    </div>



                    <p className="text-xl font-semibold text-center leading-6 text-white mt-4 mb-2">Regala i tuoi coin ai tuoi amici</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputTextBlack
                            id={'email'}
                            type={'email'}
                            errors={errors.email}
                            input={{ ...register("email", { required: true, pattern: emailRegex }) }}
                            placeholder={"Inserisci l'email"}
                        />

                        <Controller
                            name={"points"}
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    isFullW={true}
                                    label={'Quanti punti vuoi regalare?'}
                                    placeholder={'Inserisci punti'}
                                    isShowLabel={true}
                                    name={name}
                                    maxValue={currentPoint}
                                    value={value}
                                    onChange={(n) => {
                                        onChange(n);
                                    }}
                                    prefix={''}
                                    suffix={''}
                                    errors={error}
                                />
                            )}
                        />

                        {
                            excep && (
                                <div className="rounded-2xl bg-yellow-600 p-4 mt-6">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <ExclamationIcon aria-hidden="true" className="h-5 w-5 text-yellow-50" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-yellow-50">Utente non trovato</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    

                        <div className="flex items-center -mt-2">
                            <InputPrimarySubmit
                                isFullWith={true}
                                isLoading={isLoading}
                                label={'Regala coin'}
                            />
                        </div>
                    </form>


                </div>
            </div>
            

        </GameSlideAlert>
    )
}

export default Game;
